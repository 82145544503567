import type { IResource } from '../types'
import Resource from '../resource'
import type { IProject } from './types'

// Todo 交换模型的实现，包含属性和方法
export default class ProjectExchanger extends Resource<IProject> implements IProject, IResource<IProject> {
  // id = 0;
  // name = '';
  // description = '';
}
