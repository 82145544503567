<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import Header from "./bim-nd-header.vue";
import PoiScene from "../components/scene/PoiScene.vue";
import { useRoute, useRuntimeConfig } from "#imports";
import Project from '@/models/project/project.exchanger'
const route = useRoute();
const runtimeConfig = useRuntimeConfig();
const logo = runtimeConfig.public.site.logo as string;
const projectId = route.params.project_id
const defaultMenus = ref([
  {
    label: "数据<br>检索",
    url: `/lifecycle/${projectId}/models`,
  },
  {
    label: "数据<br>标准",
    url: `standard`,
  },
  {
    label: "数据<br>归集",
    url: `/bim/${projectId}/collection/model/list`,
  },
  {
    label: "数据<br>加工",
    url: `/bim/${projectId}/process/merge`,
  },
  {
    label: "数据<br>分析",
    url: `/bim/${projectId}/analyze/graph`,
  }
]);
onBeforeMount(() => {
  getProjectInfo()
})
//
const projectInfo = ref()
const jump = (url: string) => {
  if (url === 'standard') {
    const oid = projectInfo.value.organization.oid
    window.location.href = `/organization/${oid}/standard/schema`
    console.log('`/organization/${oid}/standard/schema`', `/organization/${oid}/standard/schema`);

  } else
    window.location.href = url
}

const getProjectInfo = async () => {

  const exchanger = new Project();
  exchanger.read({ url: `/api/lifecycle/models/${projectId}` }).then((result: any) => {

    projectInfo.value = result.project
  });
}
</script>
<template>
<div class="relative h-screen w-screen"
  style="background-image: radial-gradient(farthest-corner at 10% 200px, rgba(36, 81, 92, 0.8) 0%, rgba(16, 22, 31, 0.902) 40%, rgba(16, 22, 31, 0.902) 100%);">
  <Header :menus="[]" :isWeather="true" :projectInfo="projectInfo" v-if="projectInfo">
    <!-- <p name="logo">111</p> -->
    <template #logo>
      <img :src="logo" class="h-8 w-20" />
    </template>
  </Header>
  <!-- <div class="absolute right-3 px-3 z-20 top-32">
      <div class="w-16 h-16 bg-cover text-sm flex justify-center items-center text-white my-3 cursor-pointer"
        style="background-image: url(/lifecycle/menu-default.png);word-break: break-all;" v-for="menu in defaultMenus"
        @click="jump(menu.url)">
        <span v-html="menu.label"></span>
      </div>
    </div> -->
  <div class="absolute w-full top-[4.375rem]" style="pointer-events: none; height:calc(100% - 4.375rem)">
    <slot></slot>
    <!-- <img class="w-full h-full" src="/lifecycle.png" alt=""> -->
  </div>
  <!-- <div class="absolute h-full w-1/3 top-0 left-0 px-3 pointer-events-none" style="
        z-index: 2;
        background: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(10, 19, 28, 0) 0%,
          rgba(10, 19, 28, 0.5) 39%,
          rgba(10, 19, 28, 0.8) 100%
        );
      "></div> -->
  <div class="absolute h-full w-1/3 top-0 right-0 px-3 pointer-events-none" style="
        z-index: 2;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(10, 19, 28, 0) 0%,
          rgba(10, 19, 28, 0.5) 39%,
          rgba(10, 19, 28, 0.8) 100%
        );
      "></div>
  <div class="absolute h-[20rem] w-full bottom-0 left-0 px-3 z-10 pointer-events-none" style="
        z-index: 2;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(10, 19, 28, 0) 0%,
          rgba(10, 19, 28, 0.5) 39%,
          rgba(10, 19, 28, 0.8) 100%
        );
      "></div>
</div>
</template>
